<template>
  <div class="main">
    <p>4号楼IDC中心</p>
    <iframe class="excel" :src="'https://view.officeapps.live.com/op/view.aspx?src=http://nfsmid7.znglzx.cn/filedown/csff/four2.xlsx'" frameborder="0"></iframe>
    <p>申请使用集群资源请联系管理员</p>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style scoped>
.main{
  padding: 50px;
}
.excel{
  width: 1400px;
  height: 800px;
}
p{
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
}
</style>